import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../Theming/Theming';
import CloseButton from '../../../ui/CloseButton';
import MobileCategories from './MobileCategories';
import UIDrawer from '@jetshop/ui/Modal/Drawer/Drawer';
import DrawerHeader from '../../../ui/DrawerHeader';
import Logo from '../Logo';
import { useSiteContent } from '../../../Util/useSiteContent';
import { LoginWidget } from '../Util/LoginWidget';
import { ChannelSelectorDropdown } from '../ChannelSelector/ChannelSelector';
import { Link } from 'react-router-dom';

const StyledCloseButton = styled(CloseButton)`
  padding: 10px;
  margin: -10px;
`;

const Scroll = styled('div')`
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  flex: 1 1 auto;
  padding-bottom: 30px;
`;

const MobileNavWrapper = styled('div')`
  height: 100%;
`;

const StyledDrawerHeader = styled(DrawerHeader)`
  background: ${theme.colors.white};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${theme.mobileHeader.nav.padding};
  margin: 1rem 0;
  border: none;
  .logo-channel {
    display: flex;
    flex-grow: 1;
    align-items: center;
    .channel-selector-wrapper {
      margin-left: 1rem;
      [data-flight-dropdown-items] {
        margin-top: -1px;
      }
    }
    .channel-selector-button {
      font-size: 0.8rem;
      height: 36px;
      padding: 0 5px;
      border: 1px solid ${theme.colors.border};
    }
  }
`;

const LogoWrapper = styled('div')`
  padding: 10px 0;
  max-width: 38px;
  width: 100%;
`;

const NavLists = styled('div')`
  padding: 0 ${theme.mobileHeader.nav.padding};
  > * {
    margin-top: 3rem;
  }
  ul {
    li {
      display: block;
      line-height: 42px;
      text-decoration: none;
      border-bottom: 1px solid ${theme.colors.border};
    }
  }
`;

const ChannelSelectorWrapper = styled('div')``;

const MobileNav = ({
  categories,
  categoryTreeRoots,
  hideTarget,
  isOpen,
  right,
  ...rest
}) => {
  const item = useSiteContent(['MobilePageList']);
  return (
    <UIDrawer isOpen={isOpen} right={right} size={360}>
      <MobileNavWrapper>
        <StyledDrawerHeader>
          <div className="logo-channel">
            <LogoWrapper>
              <Link to="/" onClick={hideTarget}>
                <Logo mobile={true} />
              </Link>
            </LogoWrapper>
            <ChannelSelectorWrapper>
              <ChannelSelectorDropdown />
            </ChannelSelectorWrapper>
          </div>
          <StyledCloseButton onClick={hideTarget} />
        </StyledDrawerHeader>
        <Scroll>
          <MobileCategories
            hideTarget={hideTarget}
            categories={categoryTreeRoots}
            categoryTreeRoots={categoryTreeRoots}
            isOpen={isOpen}
          />
          <NavLists onClick={hideTarget}>
            {item && item.show && item.html}
            <LoginWidget />
          </NavLists>
        </Scroll>
      </MobileNavWrapper>
    </UIDrawer>
  );
};
export default MobileNav;
