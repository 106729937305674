export const config = {
  routes: {
    favourites: {
      path: process.env.REACT_APP_FAV_PATH || '/favoriter'
    },
    blog: {
      path: process.env.REACT_APP_BLOG_PATH || '/blogg',
      id: process.env.REACT_APP_BLOG_ID || 673,
      mainLevel: 1,
      menu: true
    },
    brand: {
      id: 300
    },
    outlet: {
      id: 119
    },
    category: {
      hide: false
    },
    product: {
      hide: false
    }
  },
  settings: {
    storeName: 'Hogsta Ridsport',
    siteContentId: [215, 233]
  }
};
