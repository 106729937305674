import React from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import { VariantHeading } from '../VariantSelector/VariantButtons';
import ProductLink from '@jetshop/ui/ProductLink';
import { theme } from '../../Theming/Theming';
import Image from '@jetshop/ui/Image';

const ButtonWrapper = styled('div')`
  > ul {
    display: flex;
    margin: 0 -1%;
    flex-wrap: wrap;
    > li {
      text-align: center;
      border: 1px solid #f3f3f3;
      margin: 0 0.5% 1%;
      width: 80px;
      ${theme.above.sm} {
      }
      a {
        padding: 3px;
        display: block;
        font-size: 1.2rem;
        font-family: ${theme.button.fontFamily};
      }
      &.selected {
        border-color: #aaa;
      }
      .sibling-name {
        font-size: 0.7rem;
      }
    }
  }
`;

export const SiblingButtons = ({ productList, mainProduct }) => {
  const isColor = mainProduct.customFields.filter(
    item => item.key === 'Farg'
  )[0];

  return (
    <ButtonWrapper>
      <VariantHeading>
        {isColor ? t('Other colors') : t('Variants')}
      </VariantHeading>
      <ul>
        {productList.map(product => {
          const name = isColor
            ? product.customFields.filter(item => item.key === 'Farg')[0]
            : product.customFields.filter(item => item.key === 'Variant')[0];
          const showName =
            (name && name.stringValue) ||
            (name && name.listValues && name.listValues[0]) ||
            product.name;
          const images = product?.images;
          const hasImages = images && images.length > 0;
          return (
            <li
              key={product.id}
              className={product.id === mainProduct.id ? 'selected' : ''}
            >
              <ProductLink product={product}>
                {hasImages && (
                  <Image
                    sizes={[200]}
                    aspect={'4:3'}
                    alt={images[0].alt}
                    src={images[0].url}
                  />
                )}
                <p className="sibling-name">{showName}</p>
              </ProductLink>
            </li>
          );
        })}
      </ul>
    </ButtonWrapper>
  );
};
