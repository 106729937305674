import { useQuery } from 'react-apollo';
import { getList, getData } from './helpers';
import siblingProductsQuery from './siblingProductsQuery.gql';

export const useSiblingProducts = ({ customData }) => {
  //USING ARTICLENUMBERLIST FROM CUSTOM DATA
  const dataList = getData(customData);

  const artNos = getList({ data: dataList, pos: 1 });

  //QUERY
  const result = useQuery(siblingProductsQuery, {
    variables: { articleNumbers: artNos },
    errorPolicy: 'all',
    skip: artNos?.length === 0
  });

  if (!dataList) return null;

  if (result.data?.products)
    //ADD MAIN PRODUCT
    return [...result.data.products];

  return null;
};
