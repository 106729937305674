import React from 'react';
import { styled } from 'linaria/react';
import { GridWrapper } from '../../CategoryPage/ProductGrid';
import t from '@jetshop/intl';
import {
  ProductListHeader,
  ProductListSubHeader
} from '../../ProductPage/StyledComponents';
import { useEffect } from 'react';
import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackListEvent } from '@jetshop/core/analytics/tracking';
import { ScrollBox } from '../ScrollBox';
import { theme } from '../../Theming/Theming';
import { ProductCardQuickBuy } from '../../CategoryPage/QuickBuy/ProductCardQuickBuy';

const ProductRowWrapper = styled('div')`
  width: 100%;

  //SLIDER WIDTH
  .slider-items {
    .product-card {
      margin-right: 2%;
      ${theme.only.sm} {
        width: 55%;
        margin-right: 4%;
      }
      ${theme.only.md} {
        width: 37%;
        margin-right: 3%;
      }
      ${theme.only.lg} {
        width: 27%;
      }
      ${theme.above.xl} {
        width: 21%;
      }
    }
  }
`;

const RowProduct = ({ product, quickBuy }) => {
  return (
    <ProductCardQuickBuy
      product={product}
      categoryPath={null}
    ></ProductCardQuickBuy>
  );
};

export const RowProducts = ({
  category,
  slider,
  title,
  subTitle,
  quickBuy,
  listName = 'product-row: ' + category.name,
  ...rest
}) => {
  const products =
    (category && category.products && category.products.result) || [];

  //SKIP SLIDER IF ONLY 2 PRODUCTS
  if (products.length < 3) slider = false;

  //TRACK LIKE PRODUCTGRID
  const track = useTracker();
  useEffect(() => {
    const products = category?.products?.result || [];
    // Dont track anything if there are no products to render
    if (!products || products.length === 0) return;
    // Otherwise track a list view event
    track(trackListEvent({ listName, products }));
  }, [listName, category, track]);

  if (!products) return null;

  return (
    <ProductRowWrapper data-testid="product-grid">
      {subTitle && <ProductListSubHeader>{t(subTitle)}</ProductListSubHeader>}
      {title && <ProductListHeader>{t(title)}</ProductListHeader>}
      {slider ? (
        <ScrollBox>
          {products.map(product => (
            <RowProduct
              product={product}
              quickBuy={quickBuy}
              key={category.id + '-' + product.id}
            />
          ))}
        </ScrollBox>
      ) : (
        <GridWrapper>
          {products.map(product => {
            return (
              <RowProduct
                product={product}
                quickBuy={quickBuy}
                key={category.id + '-' + product.id}
              />
            );
          })}
        </GridWrapper>
      )}
    </ProductRowWrapper>
  );
};
