import React from 'react';
import { styled } from 'linaria/react';
import { useSiteContent } from '../../../Util/useSiteContent';
import { LoginWidget } from '../Util/LoginWidget';
import LipscoreServiceWidget from '../../../Lipscore/LipscoreServiceWidget';

const TopBarLinksWrapper = styled('div')`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  > ul {
    > li {
    }
  }
  > div {
    display: flex;
    align-items: center;
  }
  .lipscore-service-review-badge-small-short {
    text {
      fill: white !important;
    }
    .lipscore-service-review-logo {
      * {
        fill: white !important;
      }
    }
  }
`;

const TopBarLinks = () => {
  const items = useSiteContent(['TopBarLinks']);
  return (
    <TopBarLinksWrapper className="top-bar-links-list">
      <LipscoreServiceWidget type="service-small" />
      {items && items.show && items.html}
      <LoginWidget />
    </TopBarLinksWrapper>
  );
};

export default TopBarLinks;
