import React, { useState } from 'react';
import t from '@jetshop/intl';
import { ProductCard } from '../ProductCard';
import { ButtonUI } from '../../ui/ButtonUI';
import AddToCart from '../../Cart/AddToCart';
import { ProductCardPopup } from './QuickBuyPopup';
import ImageBox from '../ImageBox/ImageBox';

export const ProductCardQuickBuy = ({ ...props }) => {
  const { product } = props;
  const [showPopup, setPopup] = useState(false);
  const buyable = product.stockStatus && product.stockStatus.buyable;
  const productPopup = product.hasVariants && buyable;

  if (product.categoryBox) return <ImageBox data={product} />;

  return (
    <ProductCard {...props}>
      {productPopup ? (
        <ButtonUI onClick={() => setPopup(true)} className="buy list">
          {t('Add to cart')}
        </ButtonUI>
      ) : (
        <>
          {buyable ? (
            <AddToCart
              product={product}
              quantity={1}
              selectedVariation={product}
            >
              {add => (
                <ButtonUI
                  css={{ marginTop: '1rem' }}
                  className="buy list"
                  onClick={() => {
                    add();
                  }}
                >
                  {t('Add to cart')}
                </ButtonUI>
              )}
            </AddToCart>
          ) : (
            <ButtonUI className="buy list"> {t('Read more')}</ButtonUI>
          )}
        </>
      )}
      {showPopup && (
        <ProductCardPopup cardProduct={product} close={() => setPopup(false)} />
      )}
    </ProductCard>
  );
};
