import * as translations from '../translations';
import channelsQuery from './ChannelsQuery.gql';
import routeQuery from './components/RouteQuery.gql';
import { LoadableProductPage, LoadableContentPage } from './components/Shop';
import {
  LoadableStandardCategoryPage,
  LoadableWindowedCategoryPage,
} from './components/CategoryPage/CategoryPage';
import { theme } from './components/Theme';
import createFacebookTracker from '@jetshop/core/server/tracking/facebook';

const config = {
  theme,
  apolloConfig: {
    shopid: process.env.REACT_APP_SHOP_ID || 'hogstaridsport',
    graphQLURI:
      process.env.REACT_APP_GRAPHQL_URI || 'https://storeapi.jetshop.io',
    token:
      process.env.REACT_APP_APOLLO_TOKEN ||
      '359fd7c1-8e72-4270-b899-2bda9ae6ef57',
    engineApiKey: process.env.ENGINE_API_KEY || '',
    enableGateway: false,
    channelsQuery,
  },
  trackingID: process.env.REACT_APP_GA_TRACKING_ID || 'UA-123334166-1',
  tagManagerID: process.env.REACT_APP_GTM_CONTAINER_ID || 'GTM-MJSQFD',
  relewareEnabled: true,
  intl: {
    translations,
    defaultLocale: 'en',
    options: {
      formats: {
        number: {
          SEK: {
            style: 'currency',
            currency: 'SEK',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          },
        },
      },
    },
  },
  channelOverrides: {},
  disableGeoRedirect: true,
  singleDomainMode: process.env.REACT_APP_SINGLE_DOMAIN_MODE || false,
  schemaExtensions: [],
  preserveRedirect: true,
  structuredData: {
    disableDefaultProductData: false,
  },
  trackers: [],
  serverTrackers: [
    createFacebookTracker({
      pixelID: process.env.FACEBOOK_PIXEL_ID,
      token: process.env.FACEBOOK_CAPI_TOKEN
    })
  ],
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  loginPath: '/login',
  pathsWithNoAuthRequired: ['/login', '/reset-password', '/forgot-password'],
  preload: {
    routeQuery,
    preloadComponents: {
      Product: [LoadableProductPage],
      Category: [LoadableStandardCategoryPage, LoadableWindowedCategoryPage],
      Page: [LoadableContentPage],
    },
  },
  useTrackingConsentAPI: true,
};

export default config;
