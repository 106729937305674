import { styled } from 'linaria/react';
import { theme } from '../Theming/Theming';

export const ProductListHeader = styled('h1')`
  padding: 2rem 0 2rem;
  width: 100%;
  text-align: center;
  color: ${theme.productPage.productList.headerColor};
`;

export const ProductListSubHeader = styled('h3')`
  padding: 0 0;
  width: 100%;
  text-align: center;
  color: ${theme.productPage.productList.subHeaderColor};
`;

export const ProductInfoMarginBottom = `
  margin-bottom: 0.5rem;
  ${theme.above.md} {
    margin-bottom: 0.7rem;
  }
`;
