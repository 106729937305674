import React from 'react';
import t from '@jetshop/intl';
import { Link } from 'react-router-dom';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';

export const LoginWidget = () => {
  const { loggedIn, logOut } = useAuth();
  const { routes } = useShopConfig();

  return (
    <ul>
      {loggedIn && (
        <li>
          <Link to={routes.myPages.path}>{t('My Pages')}</Link>
        </li>
      )}
      <li>
        {!loggedIn ? (
          <Link to={routes.login.path}>{t('Login')}</Link>
        ) : (
          <button onClinck={logOut}>{t('Log Out')}</button>
        )}
      </li>
    </ul>
  );
};
