import React from 'react';
import { styled } from 'linaria/react';
import { withRouter } from 'react-router-dom';
import { ProductInfoMarginBottom } from '../StyledComponents';
import { SiblingButtons } from './SiblingButtons';
import { SiblingDropdown } from './SiblingDropdown';
import { useSiblingProducts } from './useSiblingProducts';

const Wrapper = styled('div')`
  width: 100%;
  ${ProductInfoMarginBottom};
`;

export const siblingfield = 'other-colors';

const SiblingProducts = ({ customData, product }) => {
  const productList = useSiblingProducts({ customData, product });

  if (!productList) return null;
  return (
    <Wrapper id="sibling-products">
      {true ? (
        <SiblingButtons productList={productList} mainProduct={product} />
      ) : (
        <SiblingDropdown productList={productList} mainProduct={product} />
      )}
    </Wrapper>
  );
};

export default withRouter(SiblingProducts);
