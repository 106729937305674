import ChannelContext from '@jetshop/core/components/ChannelContext';
import React, { useEffect } from 'react';
import { useContext } from 'react';

const Widget = ({ type }) => {
  switch (type) {
    case 'service-small':
      return (
        <div
          className="lipscore-service-review-badge-small-short lipscore-no-border"
          data-ls-widget-height="36px"
          data-ls-widget-width="200px"
        ></div>
      );
    case 'service-large':
      return (
        <div
          className="lipscore-service-review-testimonial"
          data-ls-widget-height="150px"
          data-ls-widget-width="100%"
        ></div>
      );
    default:
      return <div class="lipscore-testimonial"></div>;
  }
};

const LipscoreServiceWidget = ({ type }) => {
  useEffect(() => {
    if (typeof window !== 'undefined' && window.lipscore)
      window.lipscore.initWidgets();
  });

  //DO NOT SHOW ON FODERBUTIK CHANNEL
  const { selectedChannel } = useContext(ChannelContext);
  if (selectedChannel.id === 2) return false;

  return <Widget type={type} />;
};

export default LipscoreServiceWidget;
