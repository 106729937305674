import React from 'react';
import { getData, getList } from './helpers';
import { styled } from 'linaria/react';
import PlusIcon from '../../ui/icons/PlusIcon';

const SiblingWrapper = styled('div')`
  display: flex;
  justify-content: center;
  position: absolute;
  top: -26px;
  left: 0;
  width: 100%;
  overflow: hidden;
  > span {
    flex-shrink: 0;
    &:not(:last-child) {
      margin-right: 3px;
    }
    width: 13px;
    height: 13px;
    border-radius: 50%;
    display: block;
    border: 1px solid #fff;
    &.border {
      border-color: #ddd;
    }
    &.icon {
      margin-left: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    svg {
      font-size: 12px;
      color: #bbb;
    }
  }
`;

export const SiblingProductCard = ({ product }) => {
  if (!product.customFields) return null;

  const data = getData(product.customFields);
  if (!data) return null;

  const colors = getList({ data, grid: true });

  const showPlus = colors && colors.length > 12;

  const showColors = showPlus ? colors.splice(0, 12) : colors;

  return (
    <SiblingWrapper className="sibling-wrapper">
      {showColors.map((color, index) => {
        const className = color === '#ffffff' ? 'border' : '';
        return (
          <span
            key={index}
            className={className}
            style={{ backgroundColor: color }}
          ></span>
        );
      })}
      {showPlus && (
        <span className="icon">
          <PlusIcon />
        </span>
      )}
    </SiblingWrapper>
  );
};
