import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackListEvent } from '@jetshop/core/analytics/tracking';
import React, { useEffect } from 'react';
import { styled } from 'linaria/react';
import { css, cx } from 'linaria';
import { Price } from '@jetshop/ui/Price';
import { theme } from '../Theming/Theming';
import { ProductCardQuickBuy } from './QuickBuy/ProductCardQuickBuy';

export const calcSpace = (col, space) => `
  width: ${(100 - (col - 1) * space) / col}%;
  margin-bottom: ${space}%; 
`;

export const priceStyle = css`
  ${Price.Wrapper} {
    display: flex;
  }
`;

export const GridWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  .product-card {
    ${theme.below.sm} {
      width: 100%;
      margin-bottom: 5%;
    }
    ${theme.only.sm} {
      ${calcSpace(theme.productGrid.col.sm, theme.productGrid.space.sm)};
    }
    ${theme.only.md} {
      ${calcSpace(theme.productGrid.col.md, theme.productGrid.space.md)};
    }
    ${theme.only.lg} {
      ${calcSpace(theme.productGrid.col.lg, theme.productGrid.space.lg)};
    }
    ${theme.above.xl} {
      ${calcSpace(theme.productGrid.col.xl, theme.productGrid.space.xl)};
    }
    &.image-box {
      ${theme.only.sm} {
        width: 100%;
      }
      ${theme.only.md} {
        width: 100%;
      }
      ${theme.only.lg} {
        ${calcSpace(2, theme.productGrid.space.lg)};
      }
      ${theme.above.xl} {
        ${calcSpace(2, theme.productGrid.space.xl)};
      }
    }
  }
`;

export function ProductGrid({
  products,
  listName,
  loading,
  className,
  ...rest
}) {
  const track = useTracker();
  useEffect(() => {
    // Dont track anything if there are no products to render
    if (!products || products.length === 0) return;

    // Otherwise track a list view event
    track(trackListEvent({ listName, products }));
  }, [listName, products, track]);

  if (!products) return null;

  return (
    <GridWrapper
      data-testid="product-grid"
      className={cx('product-grid', className)}
    >
      {products.map((product, index) => {
        return (
          <ProductCardQuickBuy
            key={index + ':' + product.articleNumber}
            product={product}
            style={{ opacity: loading ? 0.5 : 1 }}
            list={listName}
            {...rest}
          ></ProductCardQuickBuy>
        );
      })}
    </GridWrapper>
  );
}

export default ProductGrid;
