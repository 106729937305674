import React, { useState } from 'react';
import { ProductCardInner } from '../ProductCard';
import Image from '@jetshop/ui/Image/Image';
import { styled } from 'linaria/react';
import Popup from '../../ui/Popup';
import { useQuery } from 'react-apollo';
import imageBoxQuery from './ImageBoxQuery.gql';
import { theme } from '../../Theming/Theming';
import TextContent from '../../ui/TextContent';
import { contentTextElements } from '../../ContentRender/ContentText';

const ImageBoxWrapper = styled('div')`
  position: relative;
  width: 100%;
  height: 100%;
  &.link {
    cursor: pointer;
  }
`;

const StyledImage = styled(Image)`
  background-size: cover;
  padding-bottom: calc(48% + 145px) !important;
  ${theme.above.lg} {
    padding-bottom: calc(48% + 151px) !important;
  }
`;

const Content = styled('div')`
  position: absolute;
  text-align: center;
  left: 50%;
  top: 50%;
  width: 100%;
  max-width: 600px;
  padding: 5px 1rem;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  ${contentTextElements} {
    color: white;
  }
  &.dark {
    ${contentTextElements} {
      color: ${theme.colors.font};
    }
  }
`;

const ImageBoxPopup = ({ id, close }) => {
  const result = useQuery(imageBoxQuery, {
    variables: { id: id }
  });
  const category = result.data?.category;
  if (category) {
    return (
      <Popup
        modalStyles={{
          overlay: {
            background: 'rgba(0,0,0,0.2)',
            zIndex: 999
          }
        }}
        isOpen={true}
        closePopup={close}
      >
        <TextContent content={category.content} />
      </Popup>
    );
  } else return null;
};

const ImageBox = ({ data, className = '' }) => {
  const [popup, setPoup] = useState(false);

  const imageUrl = data.imageUrl.value;
  const textColor = data.textColor && data.textColor.value;
  const text = data.text?.value;
  const categoryId = data.category?.value
    ? parseInt(data.category.value.id)
    : false;
  return (
    <div
      className={`product-card image-box ${className}`}
      data-testid="product"
      data-product-card
    >
      <ProductCardInner data-testid="image-box" className="product-card-inner">
        <ImageBoxWrapper
          className={`${categoryId ? 'link' : ''}`}
          onClick={() => {
            if (categoryId) setPoup(true);
          }}
        >
          <StyledImage
            src={imageUrl}
            aspect={'80:50'}
            crop={true}
            cover
            sizes={[1, 1, 1 / 2, 1 / 2]}
          />
          {text && (
            <Content className={textColor}>
              <TextContent content={text} />
            </Content>
          )}
        </ImageBoxWrapper>
      </ProductCardInner>
      {popup && <ImageBoxPopup id={categoryId} close={() => setPoup(false)} />}
    </div>
  );
};

export default ImageBox;
