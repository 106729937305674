import React from 'react';
import { Query } from 'react-apollo';
import homeCategoriesQuery from './HomeCategoriesQuery.gql';
import { config } from '../../../../shop.config.custom';

//ARRAY MENUCATEGORIES WITH NO SUBMENU (ID)
export const cleanCategories = categories => {
  const noSubMenu = [parseInt(config.routes.blog.id, 10)];
  const removeCat = [];

  let list = [];

  categories.forEach(category => {
    let item = { ...category };
    if (noSubMenu.includes(category.id)) {
      item.hasSubcategories = false;
      item.subcategories = [];
    }
    if (!removeCat.includes(category.id)) list.push(item);
  });

  return list;
};

const CategoryProvider = ({ children }) => (
  <Query variables={{ levels: 1 }} query={homeCategoriesQuery}>
    {result => {
      const { data } = result;
      const categories = (data && data.categories) || [];
      const categoryTreeRoots = cleanCategories(categories);
      return children({ categoryTreeRoots, categories });
    }}
  </Query>
);

export default CategoryProvider;
