import React from 'react';
import { styled } from 'linaria/react';
import { convertPropsToObject } from '../ContentRender';
import { RowHeader } from './RowHeader';
import LipscoreServiceWidget from '../../Lipscore/LipscoreServiceWidget';

const Wrapper = styled('div')`
  .lipscore-widget {
    margin: 0 auto;
    max-width: 800px;
  }
`;

const LipscoreComp = ({ data, index }) => {
  const props = convertPropsToObject(data);
  return (
    <Wrapper data-index={index}>
      <RowHeader props={props} />
      <div className="lipscore-widget">
        <LipscoreServiceWidget type={'service-large'} />
      </div>
    </Wrapper>
  );
};

export default LipscoreComp;
