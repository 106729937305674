import React from 'react';
import { useSiteContent } from '../../../Util/useSiteContent';
import parse from 'html-react-parser';

export const ExtraMenuLinks = ({ tag = 'li', nameTag = 'h4' }) => {
  const Element = tag;
  const NameTag = nameTag;
  const item = useSiteContent(['ExtraMenuLinks']);
  if (!item || !item.html) return null;

  let list = parse(item.html.props.content);

  if (!Array.isArray(list)) list = [list];

  return list.map((listItem, index) => {
    if (!listItem.props) return false;
    if (listItem)
      return (
        <Element key={index + 20}>
          <NameTag>{listItem}</NameTag>
        </Element>
      );
    return false;
  });
};
