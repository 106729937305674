import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';
import siteContentQuery from './siteContentQuery.gql';
import { config } from '../../shop.config.custom';
import { convertPropsToObject } from '../ContentRender/ContentRender';
import { SiteContentHtmlParser } from './SiteContentHtmlParser';
import ChannelContext from '@jetshop/core/components/ChannelContext';

export const useSiteContent = names => {
  const { selectedChannel } = useContext(ChannelContext);

  const siteContentId =
    selectedChannel.id === 2
      ? config.settings.siteContentId[1]
      : config.settings.siteContentId[0];

  const result = useQuery(siteContentQuery, {
    variables: { id: siteContentId }
  });

  if (!result.data) return [];

  const { startPage } = result && result.data;
  const items = startPage?.data?.items;

  if (!items || items.length === 0) return null;

  const list = [];
  items.forEach(item => {
    if (
      item.properties.length > 0 &&
      names.includes(item.properties[0].value.value)
    ) {
      const props = convertPropsToObject(item);
      list.push({
        html: <SiteContentHtmlParser content={props.text.value} />,
        show: props.show.value === 'yes' ? true : false,
        image: props.image.value,
        link: props.link.value
      });
    }
  });

  if (list.length === 1) return list[0];
  return list;
};
